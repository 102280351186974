import baseConstant from './baseConstant';
import apiIntegrationFunctions from './IntegrationFunctions';
import PageTitle from './PageTitle';




const CommonFunctions = {
    IsLoaderActive: function (controlledflag) {
        var loaderObj = document.getElementById('_overlay_loader');
        if (loaderObj != null) {
            if (controlledflag == true)
                loaderObj.classList.add('_display');
            else
                loaderObj.classList.remove('_display');
        }
    },
    IsOverlayActive: function (controlledflag) {
        var loaderObj = document.getElementById('_overlay_general');
        if (loaderObj != null) {
            if (controlledflag == true)
                loaderObj.classList.add('_display');
            else
                loaderObj.classList.remove('_display');
        }
    },
    validationCheck: function (FieldId) {
        var thisField = document.getElementById(FieldId);
        var checkValidation = fieldValidationWithPlaceHolder(thisField.id, thisField.value, thisField.getAttribute('field-type'), thisField.getAttribute('field-req'));
        if (checkValidation == "ER") {
            baseConstant.errorFoundGlobal = true;
            baseConstant.firstErrorFound = true;
        }
    },
    ValidationControl: function (className) {
        baseConstant.errorFoundGlobal = false;
        baseConstant.firstErrorFound = false;
        baseConstant.firstErrorFocus = true;
        var fieldCount = document.getElementsByClassName(className);
        var eachField = null;
        for (var i = 0; i < fieldCount.length; i++) {
            eachField = fieldCount[i];
            if (eachField.getAttribute('field-req')) {
                this.validationCheck(eachField.id);
            }
            if (baseConstant.firstErrorFound && baseConstant.firstErrorFocus) {
                baseConstant.firstErrorFocus = false;
            }
        }
        return baseConstant.errorFoundGlobal;
    },
    PageRedirection: function (pagePath) {
        window.location.href = pagePath;
    },
    SamePageRedirection: function (pagePath) {
        var $_GET = [];
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (a, name, value) { $_GET[name] = value; });
        var specialURLIndex = $_GET['A']
        window.location.href = pagePath;
        if (specialURLIndex != undefined)
            window.location.reload();
    },
    ShowElement: function (ElementID, index) {
        if (index == 1) {
            this.IsOverlayActive(true);
        }
        var ElementObj = document.getElementById(ElementID);
        if (ElementObj != null) {
            ElementObj.classList.add('_display');
        }

    },
    HideElement: function (ElementID, index) {
        if (index == 1) {
            this.IsOverlayActive(false);
        }
        var ElementObj = document.getElementById(ElementID);
        if (ElementObj != null) {
            ElementObj.classList.remove('_display');
        }
    },
    ToggleElement: function (ElementID, index) {
        if (baseConstant.toggleIndicator == false) {
            this.ShowElement(ElementID, index);
            baseConstant.toggleIndicator = true;
        }
        else {
            this.HideElement(ElementID, index);
            baseConstant.toggleIndicator = false;
        }
    },
    chkGUID: function (checkedGUID) {
        var returnedFlag = false;
        if (!/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(checkedGUID)) {
            returnedFlag = false;
        }
        else {
            returnedFlag = true;
        }
        return returnedFlag;
    },
    setBodyId: function (IDString) {
        document.querySelector('body').id = IDString;
    },
    setBodyClass: function (classString, index) {
        if (index == 1) {
            document.querySelector('body').classList.add(classString);
        } else {
            document.querySelector('body').classList.remove(classString);
        }
    },
    setHTMLScrollTop: function () {
        document.querySelector('html').scrollTo(0, 0);
    },
    formatDate1: function (date) {
        var today = date.getDate() + ' ' + baseConstant.monthArray[date.getMonth()] + ' ' + date.getFullYear();
        return today;
    },
    formatDate2: function (date) {
        var d = new Date(date);
        return d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + "T" + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + (parseInt(d.getMinutes()).toString().length == 2 ? (parseInt(d.getMinutes()).toString()) : "0" + (parseInt(d.getMinutes()).toString())) + ":" + (parseInt(d.getSeconds()).toString().length == 2 ? (parseInt(d.getSeconds()).toString()) : "0" + (parseInt(d.getSeconds()).toString()));
    },
    formatAMPM: function (date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    },
    ToJavaScriptDate: function (value, index) {
        var pattern = /Date\(([^)]+)\)/;
        var results = pattern.exec(value);
        if (results != null && results != undefined && results != '') {
            var dt = new Date(parseFloat(results[1]));
            var currentYear = parseInt(dt.getFullYear());
            var currentHour = dt.getHours();
            var currentMin = dt.getMinutes();
            if (currentHour == 0) {
                currentHour += '0';
            }
            if (currentMin == 0) {
                currentMin += '0';
            }
            if (currentMin.toString().length == 1) {
                currentMin = '0' + currentMin;
            }
            if (currentYear > 1753) {
                if (index == 0) {
                    return this.formatDate1(dt);
                }
                else if (index == 1) {
                    return this.formatDate1(dt) + " " + this.formatAMPM(dt);
                }
                else if (index == 2) {
                    return this.formatAMPM(dt);
                }
                else if (index == 3) {
                    return currentHour + ":" + currentMin;
                }
                else if (index == 4) {
                    return this.formatDate1(dt) + ' ' + currentHour + ":" + currentMin;
                }
                else if (index == 5) {
                    return currentHour + ":" + currentMin;
                }
                else {
                    return this.formatDate1(dt) + " " + this.formatAMPM(dt);
                }
            }
            else {
                return '';
            }
        }
        else {
            var convertToDateTime = new Date(value);
            var currentHour = convertToDateTime.getHours();
            var currentMin = convertToDateTime.getMinutes();
            var currentYear = parseInt(convertToDateTime.getFullYear());
            if (currentHour == 0) {
                currentHour += '0';
            }
            if (currentMin == 0) {
                currentMin += '0';
            }
            if (currentMin.toString().length == 1) {
                currentMin = '0' + currentMin;
            }
            if (currentYear > 1753) {
                if (index == 0) {
                    return this.formatDate1(convertToDateTime);
                }
                else if (index == 1) {
                    return this.formatDate1(convertToDateTime) + " " + this.formatAMPM(convertToDateTime);
                }
                else if (index == 2) {
                    return this.formatAMPM(convertToDateTime);
                }
                else if (index == 3) {
                    return currentHour + ":" + currentMin;
                }
                else if (index == 4) {
                    return this.formatDate1(convertToDateTime) + ' ' + currentHour + ":" + currentMin;
                }
                else if (index == 5) {
                    return currentHour + ":" + currentMin;
                }
                else {
                    return this.formatDate1(convertToDateTime) + " " + this.formatAMPM(convertToDateTime);
                }
            }
            return '';
        }
    },
    getFeatureAccessIdentifier: function (moduleCode) {
        // var accessObject = [];
        var accessObject = {
            module_name: '',
            module_code: '',
            show_view: true,
            show_add: true,
            show_edit: true,
            show_delete: true
        };
        return accessObject;
        var accessListArrayJSON = localStorage[baseConstant.userRolePageFeatureAccessList];
        //alert(accessListArrayJSON)
        if (accessListArrayJSON != undefined && accessListArrayJSON != '') {
            var accessListArray = JSON.parse(accessListArrayJSON);
            if (accessListArray != null) {
                for (var i = 0; i < accessListArray.length; i++) {
                    var accessObjectArray = accessListArray[i];
                    if (accessObjectArray != null) {
                        if (accessObjectArray.module_code === moduleCode) {
                            accessObject = {
                                module_name: accessObjectArray.module_name,
                                module_code: accessObjectArray.module_code,
                                show_view: accessObjectArray.show_view == 'Y' ? true : false,
                                show_add: accessObjectArray.show_add == 'Y' ? true : false,
                                show_edit: accessObjectArray.show_edit == 'Y' ? true : false,
                                show_delete: accessObjectArray.show_delete == 'Y' ? true : false

                                //module_name: accessObjectArray.module_name,
                                //module_code: accessObjectArray.module_code,
                                //show_view: accessObjectArray.show_view == true,
                                //show_add: accessObjectArray.show_add == true,
                                //show_edit: accessObjectArray.show_edit == true,
                                //show_delete: accessObjectArray.show_delete == true
                            };
                            break;
                        } else {
                            accessObject = [];
                        }
                    } else {
                        accessObject = [];
                    }
                }
            } else {
                accessObject = [];
            }

        } else {
            accessObject = [];
        }
        return accessObject;
    },
    returnDateObject: function (dateValue) {
        var returnDate = new Date();
        if (dateValue != '' && dateValue != null && dateValue != '') {
            returnDate = new Date(dateValue);
        }
        return returnDate;
    },
    passwordShowHideController: function (inputFieldID, controllerID) {
        var fieldDOM = document.getElementById(inputFieldID);
        var controllerDOM = document.getElementById(controllerID);
        if (fieldDOM != null && controllerDOM != null) {
            if (fieldDOM.type == 'password') {
                fieldDOM.type = 'text';
                controllerDOM.classList.add('passwordShow');
            } else {
                fieldDOM.type = 'password';
                controllerDOM.classList.remove('passwordShow');
            }
        }
    },
    getDropDownList: function (inputVal, actionType, response) {
        this.IsLoaderActive(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                searchNumParam: inputVal,
                searchActionIdentifier: actionType,
                apiParameters: baseConstant.apiParametersWithoutLogin
            }),
            mode: 'cors',
        };
        fetch(baseConstant.serverAPIUrl + apiIntegrationFunctions.getDropDownList, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.IsLoaderActive(false);
                    if (result != null) {
                        if (result.lastCallStatus == true) {
                            response(result.lastCallData);
                        }
                    }
                },
                //
                // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions 
                // from actual bugs in components.
                // 
                (error) => {
                    this.IsLoaderActive(false);
                }
            )
    },
    getDropDownListSecond: function (inputVal, actionType, response) {
        this.IsLoaderActive(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                searchNumParam: inputVal,
                searchActionIdentifier: actionType,
                apiParameters: baseConstant.apiParametersWithoutLogin
            }),
            mode: 'cors',
        };
        fetch(baseConstant.serverAPIUrl + apiIntegrationFunctions.getDropDownListSecond, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.IsLoaderActive(false);
                    if (result != null) {
                        if (result.lastCallStatus == true) {
                            response(result.lastCallData);
                        }
                    }
                },
                //
                // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions 
                // from actual bugs in components.
                // 
                (error) => {
                    this.IsLoaderActive(false);
                }
            )
    },
    getDropDownListThird: function (inputVal1, inputVal2, actionType, response) {
        this.IsLoaderActive(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                searchNumParam: inputVal1,
                searchNumParam2: inputVal2,
                searchActionIdentifier: actionType,
                apiParameters: baseConstant.apiParametersWithoutLogin
            }),
            mode: 'cors',
        };
        fetch(baseConstant.serverAPIUrl + apiIntegrationFunctions.getDropDownList, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.IsLoaderActive(false);
                    if (result != null) {
                        if (result.lastCallStatus == true) {
                            response(result.lastCallData);
                        }
                    }
                },
                //
                // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions 
                // from actual bugs in components.
                // 
                (error) => {
                    this.IsLoaderActive(false);
                }
            )
    },
    loginActiveDeactive: function (receivedObject, authTokens, response) {
        if (receivedObject != null) {
            if (this.bIsTableIdValued(receivedObject.user_id)) {
                this.IsLoaderActive(true);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                if (authTokens != null && authTokens !== '' && authTokens !== undefined) {
                    myHeaders.append('Authorization', authTokens);
                }
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify({
                        userData: {
                            user_id: receivedObject.user_id,
                            is_active: receivedObject.is_active
                        },
                        apiParameters: baseConstant.apiParameters
                    }),
                    mode: 'cors',
                };
                fetch(baseConstant.serverAPIUrl + apiIntegrationFunctions.loginActiveDeactive, requestOptions)
                    .then(res => res.json())
                    .then(
                        (result) => {
                            this.IsLoaderActive(false);
                            if (result != null) {
                                if (result.lastCallStatus == true) {
                                    response(result);
                                }
                            }
                        },
                        //
                        // Note: it's important to handle errors here instead of a catch() block so that we don't swallow exceptions 
                        // from actual bugs in components.
                        // 
                        (error) => {
                            this.IsLoaderActive(false);
                        }
                    )
            }
        }
    },
    ajaxCall: function (apiCallUrl, jsonData, authTokens, method, callback) {
        this.IsLoaderActive(true);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (authTokens != null && authTokens !== '' && authTokens !== undefined) {
            //myHeaders.append('Authorization', `Bearer  ${authTokens}`);
            myHeaders.append('Authorization', authTokens);
        }
        var requestOptions = {
            method: method,
            headers: myHeaders,
            body: jsonData,
            redirect: 'follow',
            mode: 'cors',
        };
        fetch(baseConstant.serverAPIUrl + apiCallUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.IsLoaderActive(false);
                    callback(result, true);
                },
                // Note: it's important to handle errors here instead of a catch()
                //  block so that we don't swallow exceptions from actual bugs in components.
                (error) => {
                    this.IsLoaderActive(false);
                    callback(error, false);
                }
            )
    },
    ajaxFormDataCall: function (apiCallUrl, formData, authTokens, method, callback) {
        this.IsLoaderActive(true);

        var myHeaders = new Headers();
        if (authTokens != null && authTokens !== '' && authTokens !== undefined) {
            //myHeaders.append('Authorization', `Bearer  ${authTokens}`);
            myHeaders.append('Authorization', authTokens);
        }
        var requestOptions = {
            method: method,
            headers: myHeaders,
            body: formData,
            redirect: 'follow',
            mode: 'cors',
        };
        fetch(baseConstant.serverAPIUrl + apiCallUrl, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    this.IsLoaderActive(false);
                    callback(result, true);
                },
                // Note: it's important to handle errors here instead of a catch()
                //  block so that we don't swallow exceptions from actual bugs in components.
                (error) => {
                    this.IsLoaderActive(false);
                    callback(error, false);
                }
            )
    },
    dateBeforeNthDay: function (n) {
        var TodayDate = new Date();
        TodayDate.setDate(TodayDate.getDate() - n);
        var previousDate = this.ToJavaScriptDate(TodayDate, 0);
        return previousDate;
    },
    DateAfterNthDay: function (n) {
        var TodayDate = new Date();
        TodayDate.setDate(TodayDate.getDate() + n);
        var FutureDate = this.ToJavaScriptDate(TodayDate, 0);
        return FutureDate;
    },
    ConvertToInteger: function (Value) {
        if (Value != null && Value != undefined && Value != '')
            return parseInt(Value);
        else
            return 0;
    },
    getGreaterIntegerValue: function (value) {
        var retunedValue = Math.round(value);
        if (!isNaN(value)) {
            var roundValue = retunedValue;
            if (roundValue < value) {
                retunedValue = roundValue + 1;
            } else {
                retunedValue = roundValue;
            }
        }
        return retunedValue;
    },
    dateCompareMethod: function (DateTimeString) {
        var returnedFlag = -1;
        if (DateTimeString != '') {
            var selectedDateTime = (new Date(this.ToJavaScriptDate(DateTimeString, 0))).getTime();

            var currentSystemDateTime = new Date(this.ToJavaScriptDate(new Date(), 0)).getTime();
            returnedFlag = (selectedDateTime > currentSystemDateTime) ? 1 : (selectedDateTime == currentSystemDateTime ? 0 : -1);
        }
        return returnedFlag;
    },
    dateTimeCompareMethod: function (DateTimeString) {
        var returnedFlag = -1;
        if (DateTimeString != '') {
            var selectedDateTime = (new Date(DateTimeString)).getTime();

            var currentSystemDateTime = new Date().getTime();
            returnedFlag = (selectedDateTime > currentSystemDateTime) ? 1 : (selectedDateTime == currentSystemDateTime ? 0 : -1);
        }
        return returnedFlag;
    },
    twoDateCompareMethod: function (date1, date2) {
        var returnedFlag = -1;
        var date1Operated = this.ToJavaScriptDate(date1, 0);
        var date2Operated = this.ToJavaScriptDate(date2, 0);
        var date1Obj = new Date(date1Operated);
        var date2Obj = new Date(date2Operated);
        if (date1Obj.getTime() == date2Obj.getTime()) {
            returnedFlag = 0;
        } else if (date1Obj.getTime() > date2Obj.getTime()) {
            returnedFlag = -1;
        } else {
            returnedFlag = 1;
        }
        return returnedFlag;
    },
    convertUTCDateToLocalDate: function (date) {
        var newDate = new Date(new Date(date).getTime() - (new Date()).getTimezoneOffset() * 60 * 1000);
        return newDate;
    },
    resettingAllVAlidatedFields: function (identifiedClass) {
        var elements = document.getElementsByClassName(identifiedClass);
        for (var i = 0; i < elements.length; i++) {
            elements[i].classList.remove(errorFieldBordeer);
            elements[i].classList.remove(errorPlaceholder);
        }
        var phoneElements = document.getElementsByClassName('inputTelPhone');
        for (var i = 0; i < phoneElements.length; i++) {
            phoneElements[i].classList.remove(errorFieldBordeer);
            phoneElements[i].classList.remove(errorPlaceholder);
        }
    },
    dataURLtoFile: function (dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    },
    fileToDataURL: function (fileObj, response) {
        var reader = new FileReader();
        reader.readAsDataURL(fileObj);
        reader.onload = function () {
            response(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    },
    copySectionText: function (Id) {
        var copyText = document.getElementById(Id);
        if (copyText != null) {
            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/
            /* Copy the text inside the text field */
            document.execCommand("copy");
        }
    },
    setDynamicWebPageTitle: function (titleTxt) {
        document.querySelector('title').innerHTML = titleTxt;
        document.querySelector('meta[property="og:title"]').setAttribute("content", titleTxt);
        document.querySelector('meta[name="twitter:title"]').setAttribute("content", titleTxt);
    },
    setDynamicWebPageMetaDescription: function (descTxt) {
        document.querySelector('meta[name="description"]').setAttribute("content", descTxt);
        document.querySelector('meta[property="og:description"]').setAttribute("content", descTxt);
        document.querySelector('meta[name="twitter:description"]').setAttribute("content", descTxt);
    },
    setDynamicWebPageMetaKeywords: function (keywords) {
        document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
    },
    setDynamicCanonicalTag: function (pageURL) {
        document.querySelector('link[rel="canonical"]').setAttribute("href", pageURL);
        document.querySelector('meta[property="og:url"]').setAttribute("content", pageURL);
    },
    getPathWithoutDomain: function () {
        // Get the complete URL
        var fullURL = window.location.href;
        // Get the domain
        var domain = window.location.protocol + '//' + window.location.host;
        // Remove the domain part from the complete URL
        var pathWithoutDomain = fullURL.replace(domain, '');
        return pathWithoutDomain;
    },
    specificMaxheightControlMethod: function (elementRef, elementHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.maxHeight = elementHT + 'rem';
            }
        }
    },
    specificMinheightControlMethod: function (elementRef, elementHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.minHeight = elementHT + 'rem';
            }
        }
    },
    heightControlMethod: function (elementRef, adjustHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.maxHeight = 'calc(' + (window.innerHeight) + 'px - ' + adjustHT + 'rem';
            }
        }
    },
    specificHeightControlMethod: function (elementRef, elementHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.height = elementHT + 'rem';
            }
        }
    },
    heightControlSpecialMethod: function (elementRef, adjustHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.height = 'calc(' + (window.innerHeight) + 'px - ' + adjustHT + 'rem';
            }
        }
    },
    minHeightControlMethod: function (elementRef, adjustHT) {
        if (elementRef != null && elementRef != undefined) {
            if (elementRef.current != undefined && elementRef.current != null) {
                elementRef.current.style.minHeight = 'calc(' + (window.innerHeight) + 'px - ' + adjustHT + 'rem';
            }
        }
    },
    getRandomColorCode: function () {
        // Generate random values for red, green, and blue components
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);

        // Convert the decimal values to hexadecimal
        const redHex = red.toString(16).padStart(2, '0');
        const greenHex = green.toString(16).padStart(2, '0');
        const blueHex = blue.toString(16).padStart(2, '0');

        // Combine the hexadecimal values to form a color code
        const colorCode = `#${redHex}${greenHex}${blueHex}`;
        return colorCode;
    },
    accordionController: function (accrBtnId, formSecId) {
        var accrBtnDOMObj = document.getElementById(accrBtnId);
        var formSecDOMObj = document.getElementById(formSecId);
        if (accrBtnDOMObj != null && formSecDOMObj != null) {
            if (accrBtnDOMObj.classList.contains('closed')) {
                accrBtnDOMObj.classList.remove('closed');
            } else {
                accrBtnDOMObj.classList.add('closed');
            }
            if (formSecDOMObj.classList.contains('closed')) {
                formSecDOMObj.classList.remove('closed');
            } else {
                formSecDOMObj.classList.add('closed');
            }
        }
    },
    openFullscreen: function () {
        var elemnt = document.documentElement;
        if (elemnt.requestFullscreen) {
            elemnt.requestFullscreen();
        } else if (elemnt.webkitRequestFullscreen) { /* Safari */
            elemnt.webkitRequestFullscreen();
        } else if (elemnt.msRequestFullscreen) { /* IE11 */
            elemnt.msRequestFullscreen();
        }
        return true;
    },
    closeFullscreen: function () {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
        return false;
    },
    bIsTableIdValued: function (id) {
        var idExact = parseInt(id);
        if (idExact != undefined && idExact != null && idExact != '' && idExact > -1 && isNaN(idExact) == false) {
            return true;
        } else {
            return false;
        }
    },
    properStrValueExistance: function (val) {
        if (val != undefined && val != null && val != '') {
            return true;
        } else {
            return false;
        }
    },
    returnedDateFormatFromTime: function (slotTime) {
        if (slotTime != '' && slotTime != undefined && slotTime != null) {
            return new Date(CommonFunctions.ToJavaScriptDate(new Date(), 0) + ' ' + slotTime);
        } else {
            return new Date(CommonFunctions.ToJavaScriptDate(new Date(), 4));
        }
    },
    listActionMenuControl: function (elementId) {
        var domObjectArray = document.getElementsByClassName('action-dropdown-list');
        for (var i = 0; i < domObjectArray.length; i++) {
            if (domObjectArray[i].id != elementId) {
                domObjectArray[i].classList.remove('appeared');
            }
        }
        if (this.properStrValueExistance(elementId)) {
            var activeElement = document.getElementById(elementId);
            if (!activeElement.classList.contains('appeared')) {
                activeElement.classList.add('appeared');
            } else {
                activeElement.classList.remove('appeared');
            }
        }
    },
    userLogOutActivity: function () {
        localStorage[baseConstant.userAccessIdentifier] = '';
        localStorage[baseConstant.tokens] = '';
        localStorage[baseConstant.userLoginDetails] = '';
        this.PageRedirection(PageTitle.loginURL);
    }
}
export default CommonFunctions
var InvalidText = ' is invalid'
var errorPlaceholder = 'errorPlaceholder';
var errorFieldBordeer = 'errorFieldBordeer';
function fieldValidationWithPlaceHolder(fieldId, fieldValue, fieldType, isReq) {
    var ElementObj = document.getElementById(fieldId);
    switch (fieldType) {
        case "TEXT_ONLY":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^[a-zA-Z ]*$/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }
                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }
                break;

            }
            else {
                return "SU";
                break;
            }

        case "NUMONLY":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^[0-9]*$/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }

                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                if (/^[0-9]*$/.test(fieldValue) == false) {
                    PlaceholderControl(fieldId, 1);
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }
                else {
                    PlaceholderControl(fieldId, 0);
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }

            }

        case "PRICE":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^(\d{1,3})?(,?\d{3})*(\.\d{1,4})?$/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }

                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                if (/^(\d{1,3})?(,?\d{3})*(\.\d{2})?$/.test(fieldValue) == false) {
                    PlaceholderControl(fieldId, 1);
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }
                else {
                    PlaceholderControl(fieldId, 0);
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }

            }
        case "FLOAT":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^[-+]?[0-9]+\.[0-9]+$/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }

                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                if (/^[-+]?[0-9]+\.[0-9]+$/.test(fieldValue) == false) {
                    PlaceholderControl(fieldId, 1);
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }
                else {
                    PlaceholderControl(fieldId, 0);
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }

            }
        case "EMAIL":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+([\.][a-z0-9-]+)+$/i.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }

                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                if (fieldValue != "") {
                    if (/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+([\.][a-z0-9-]+)+$/i.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }
                else {
                    PlaceholderControl(fieldId, 0);
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }

            }
        case "WEBURL":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (fieldValue.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g) == null) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }

                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                return "SU";
                break;

            }

        case "DD_ONLY":
            if (isReq == "True") {
                if (fieldValue == "") {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }
                else {
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }
            }
            else {
                return "SU";
                break;
            }

        case "PHONE":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/^[0-9\-]{7,12}$/.test(fieldValue) == false && /^\d{10}$/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }
                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                return "SU";
                break;

            }
        case "TEXTAREA":
            if (isReq == "True") {
                if (fieldValue != "") {
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }
                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                return "SU";
                break;
            }
        case "REQUIRED":
            if (isReq == "True") {
                if (fieldValue != "") {
                    ValidationActiveDeActive(fieldId, 0);
                    return "SU";
                    break;
                }
                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                return "SU";
                break;

            }
        case "PASSWORD":
            if (isReq == "True") {
                if (fieldValue != "") {
                    if (/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{4,20})/.test(fieldValue) == false) {
                        PlaceholderControl(fieldId, 1);
                        ValidationActiveDeActive(fieldId, 1);
                        return "ER";
                        break;
                    }
                    else {
                        PlaceholderControl(fieldId, 0);
                        ValidationActiveDeActive(fieldId, 0);
                        return "SU";
                        break;
                    }
                }
                else {
                    ValidationActiveDeActive(fieldId, 1);
                    return "ER";
                    break;
                }

            }
            else {
                return "SU";
                break;

            }
        case "NONE":
            break;
    }
}


function ValidationActiveDeActive(fieldId, index) {
    var ReactDOMElement = document.getElementById(fieldId);
    if (index == 0) {
        ReactDOMElement.classList.remove(errorFieldBordeer);
        ReactDOMElement.classList.remove(errorPlaceholder);
    }
    else if (index == 1) {
        ReactDOMElement.classList.add(errorFieldBordeer);
        ReactDOMElement.classList.add(errorPlaceholder);
    }
}
//PlaceholderControl
function PlaceholderControl(fieldId, index) {
    var ReactDOMElement = document.getElementById(fieldId);
    var originalPlaceholder = ReactDOMElement.getAttribute('field-placeholder');
    var fieldTitle = ReactDOMElement.getAttribute('field-title');
    if (index == 1) {
        ReactDOMElement.placeholder = fieldTitle + InvalidText;
    }
    else if (index == 0) {
        ReactDOMElement.placeholder = originalPlaceholder;
    }
}
