import apiIntegrationFunctions from "./IntegrationFunctions";
import PageTitle from "./PageTitle";

var baseConstant = {
    /**General variable***/

    apiParameters: { "accessAPPApiKey": apiIntegrationFunctions.accessAPPApiKey, "userIdentifier": localStorage.getItem("userAccessIdentifier") },
    apiParametersWithoutLogin: { "accessAPPApiKey": apiIntegrationFunctions.accessAPPApiKey },
	/***Dummy Token to run the application pages without login session activity***/
    crmDummyToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtZXNzYWdlIjoiSldUIFJ1bGVzISIsImlhdCI6MTQ1OTQ0ODExOSwiZXhwIjoxNDU5NDU0NTE5fQ.-yIVBD5b73C75osbmwwshQNRC7frWUYrqaTjTpza2y4",
    /***End of this portion of the code**/
    blankGUID: '00000000-0000-0000-0000-000000000000',
    blankDate: '01-01-0001',
    systemDateFormat: 'dd MMM yyyy',
    monthArray: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],


    userRolePageFeatureAccessList: "userRolePageFeatureAccessList",
    /**End of General variable***/


    /**General message***/

    error_message: "Something has gone wrong, Please try again later..!!",
    success_saved: "Successfully saved..!",
    formFillValidationErrorMessage: "Please fill up all the required fields ",

    _serp_FileUpload_MaxFileSize: 10485760,
    error_file_size_validation_message: 'File is too big. Please reduce file size and upload again.',
    error_file_format_validation_message: 'Invalid file format. Please select a different file and try again.',
    file_missing_error_message: 'Please browse a file',

    /**End of General message***/


    /**General Array List***/

    appGenderList: [
        {
            text: 'Male',
            value: 'Male'
        },
        {
            text: 'Female',
            value: 'Female'
        },
        {
            text: 'Transgender',
            value: 'Transgender'
        },
        {
            text: 'Not Disclosed',
            value: 'Not Disclosed'
        }
    ],
    appCasteList: [
        {
            text: 'General',
            value: 'General'
        },
        {
            text: 'SC',
            value: 'SC'
        },
        {
            text: 'ST',
            value: 'ST'
        },
        {
            text: 'OBC',
            value: 'OBC'
        },
        {
            text: 'Minority',
            value: 'Minority'
        },
        {
            text: 'Others',
            value: 'Others'
        },
        {
            text: 'Not Disclosed',
            value: 'Not Disclosed'
        }
    ],

    appMonthList: [
        {
            text: 'January',
            value: 'Jan'
        },
        {
            text: 'February',
            value: 'Feb'
        },
        {
            text: 'March',
            value: 'Mar'
        },
        {
            text: 'April',
            value: 'Apr'
        },
        {
            text: 'May',
            value: 'May'
        },
        {
            text: 'June',
            value: 'Jun'
        },
        {
            text: 'July',
            value: 'Jul'
        },
        {
            text: 'August',
            value: 'Aug'
        },
        {
            text: 'September',
            value: 'Sep'
        },
        {
            text: 'October',
            value: 'Oct'
        },
        {
            text: 'November',
            value: 'Nov'
        },
        {
            text: 'December',
            value: 'Dec'
        }
    ],
    appPurposeList: [
        {
            text: 'Relocation',
            value: 'REL'
        },
        {
            text: 'Retirement',
            value: 'RET'
        },
        {
            text: 'Disposal',
            value: 'DIS'
        },
        {
            text: 'Upgrade',
            value: 'UPG'
        },
        {
            text: 'Transfer',
            value: 'TRF'
        },
        {
            text: 'Maintenance',
            value: 'MNT'
        },
        {
            text: 'Calibration',
            value: 'CAL'
        },
        {
            text: 'Insurance',
            value: 'INS'
        },
        {
            text: 'Lease End',
            value: 'LSE'
        },
        {
            text: 'Salvage',
            value: 'SLV'
        },
        {
            text: 'Breakdown',
            value: 'BRK'
        },
        {
            text: 'Theft',
            value: 'THFT'
        },
        {
            text: 'Other',
            value: 'OTH'
        }
    ],
    appBloodGroupList: [
        {
            text: 'A+',
            value: 'A+'
        },
        {
            text: 'A-',
            value: 'A-'
        },
        {
            text: 'B+',
            value: 'B+'
        },
        {
            text: 'B-',
            value: 'B-'
        },
        {
            text: 'O+',
            value: 'O+'
        },
        {
            text: 'O-',
            value: 'O-'
        },
        {
            text: 'AB+',
            value: 'AB+'
        },
        {
            text: 'AB-',
            value: 'AB-'
        }
    ],
    appRoleList: [
        {
            text: 'Admin',
            value: 1
        },
        {
            text: 'IT',
            value: 2
        },
        {
            text: 'Human Resources',
            value: 3
        },
        {
            text: 'State HR Head ',
            value: 4
        },
        
    ],
    appWeekList: [

        {
            text: 'Monday',
            value: 'Monday'
        },
        {
            text: 'Tuesday',
            value: 'Tuesday'
        },
        {
            text: 'Wednesday',
            value: 'Wednesday'
        },
        {
            text: 'Thursday',
            value: 'Thursday'
        },
        {
            text: 'Friday',
            value: 'Friday'
        },
        {
            text: 'Saturday',
            value: 'Saturday'
        },
        {
            text: 'Sunday',
            value: 'Sunday'
        },

    ],
    userRoles: [
        {
            text: "Admin",
            value: 1,
        },
        {
            text: "IT",
            value: 2,
        },
        // {
        //     text: "Human Resources",
        //     value: 3,
        // },
        // {
        //     text: "State HR Head",
        //     value: 4,
        // },
        // {
        //     text: "Others",
        //     value: 12,
        // },
    ],
    appBucketPayrollList: [
        {
            text: 'Vodafone',
            value: 'Vodafone'
        },
        {
            text: 'Airtel',
            value: 'Airtel'
        },
        {
            text: 'Jio',
            value: 'Jio'
        },
        {
            text: 'Sulk',
            value: 'Sulk'
        },
        {
            text: 'Indus',
            value: 'Indus'
        },
        {
            text: 'Tata',
            value: 'Tata'
        },
        {
            text: 'Common',
            value: 'Common'
        },
    ],
    historyTypeList: [
        {
            text: 'Arrived',
            value: 'Arrived',
        },
        {
            text: 'Transfer',
            value: 'Transfer'
        },
        {
            text: 'Maintenance',
            value: 'Maintenance'
        },
        {
            text: 'Returned',
            value: 'Returned'
        },
    
        {
            text: 'Destroyed',
            value: 'Destroyed'
        }
    ],
    day: [
        {
            value: 1,
            label: "Today"
        },
        {
            value: 2,
            label: "Current Month"
        },
        {
            value: 3,
            label: "Last Month"
        },
        {
            value: 4,
            label: "Current Year"
        },
        {
            value: 5,
            label: "Last Year"
        },
    ],
    religion: [
        {
            value: "Hinduism",
            label: "Hinduism"
        },
        {
            value: "Islam",
            label: "Islam"
        },
        {
            value: "Sikhism",
            label: "Sikhism"
        },
        {
            value: "Christianity",
            label: "Christianity"
        },
        {
            value: "Others",
            label: "Others"
        },
    ],
    pageCodes: [
        {
            module_name: "Dashboard",
            module_code: PageTitle.dashBoardPageCode,   //"ASSET_DASHBOARD_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Branch",
            module_code: PageTitle.branchPageCode,   //"ASSET_BRANCH_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Category",
            module_code: PageTitle.categoryPageCode,   //"ASSET_CATEGORY_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        // {
        //     module_name: "Asset",
        //     module_code: PageTitle.assetPageCode,   //"ASSET_ASSET_CODE"
        //     show_view: "N",
        //     show_add: "N",
        //     show_edit: "N",
        //     show_delete: "N",
        // },
        {
            module_name: "Add Asset",
            module_code: PageTitle.addAssetPageCode,  //"ASSET_ADDASSET_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "All Asset",
            module_code: PageTitle.assetAllAssetPageCode,   //"ASSET_ALLASSET_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset - Asset Issue",
            module_code: PageTitle.assetIssuePageCode,
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset -  Asset Transfer",
            module_code: PageTitle.assetTransferPageCode,
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset -  Asset Maintenance",
            module_code: PageTitle.assetMaintenancePageCode,
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset -  Asset Return",
            module_code: PageTitle.assetReturnPageCode,
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset -  Asset Scrap",
            module_code: PageTitle.assetScrapPageCode,
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset Multiple Import",
            module_code:  PageTitle.assetMultipleImportPageCode,   //"ASSET_ASSET_MULTIPLE_IMPORT_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        // {
        //     module_name: "Vendor",
        //     module_code: PageTitle.vendorPageCode,    //"ASSET_VENDOR_CODE"
        //     show_view: "N",
        //     show_add: "N",
        //     show_edit: "N",
        //     show_delete: "N",
        // },
        {
            module_name: "Vendor List",
            module_code: PageTitle.vendorListPageCode,    //"ASSET_VENDORLIST_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Vendor Multiple Import",
            module_code: PageTitle.vendorMultipleImportPageCode,    //"ASSET_VENDOR_MULTIPLE_IMPORT_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        // {
        //     module_name: "Employee",
        //     module_code: PageTitle.employeePageCode,    //"ASSET_EMPLOYEE_CODE"
        //     show_view: "N",
        //     show_add: "N",
        //     show_edit: "N",
        //     show_delete: "N",
        // },
        {
            module_name: "Employee - Employee List",
            module_code: PageTitle.employeeListPageCode,    //"ASSET_EMPLOYEE_LIST_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Employee - Add Department",
            module_code: PageTitle.employeeDepartmentAdditionPageCode,   //"ASSET_EMPLOYEE_DEPARTMENT_ADD_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Employee - Add Destination",
            module_code: PageTitle.employeeDesignationPageCode,   //"ASSET_EMPLOYEE_DESIGNATION_ADD_CODE"
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Employee - Add Employee",
            module_code: PageTitle.employeeAdditionPageCode,  //"ASSET_EMPLOYEE_ADD_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Employee Multiple Import",
            module_code: PageTitle.employeeMultipleImportPageCode,   //"ASSET_EMPLOYEE_MULTIPLE_IMPORT_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset Manufacturer",
            module_code: PageTitle.manufacturerPageCode,    //"ASSET_MANUFACTURER_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        // {
        //     module_name: "Asset Report",
        //     module_code: PageTitle.reportPageCode,     //"ASSET_REPORT_CODE",
        //     show_view: "N",
        //     show_add: "N",
        //     show_edit: "N",
        //     show_delete: "N",
        // },
        {
            module_name: "Asset Report - Transfer",
            module_code: PageTitle.assetTransferReportPageCode,     //"ASSET_TRANSFER_REPORT_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "Asset Report - Maintenance",
            module_code: PageTitle.assetMaintenanceReportPageCode,   //"ASSET_MAINTENANCE_REPORT_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
        {
            module_name: "User Access",
            module_code: PageTitle.userAccessPageCode,   //"ASSET_USER_ACCESS_CODE",
            show_view: "N",
            show_add: "N",
            show_edit: "N",
            show_delete: "N",
        },
    ],

    /***End of general array***/



    /**server enviroment***/

    //serverAPIUrl: "http://localhost/asset/narulaasset/",
    //serverAPIUrlContent: "http://localhost/asset/narulaasset/images/",
    //serverAPIUrlContent: "http://localhost/asset/narulaasset/controller/content/applicationFiles/",

    serverAPIUrl: "https://ansysoft.com/ams-api/",
    serverAPIUrlContent: "https://ansysoft.com/ams-api/images/",

    
    /**End of local enviroment***/
    


    /**Local storage title***/
    storedLoginCredentials: "storedLoginCredentials",
    userAccessIdentifier: "userAccessIdentifier",
    userCredentials: "userCredentials",
    storedAssetId: "storedAssetId",
    storedAssetAssignedEmp: "storedAssetAssignedEmp",
    storedComplaintId:"storedComplaintId",
    storedStaffId: "storedStaffId",
    /**End of Local storage title***/
}
export default baseConstant

